<template>
    <modal ref="modalEditarVigencia" :titulo="titulo" icon="porcent" :cargando="loading" @guardar="editarVigencia">
        <ValidationObserver ref="validacion">
            <div class="row m-3 justify-center text-center">
                <div v-if="delBuscador" class="col-12">
                    <p>Antes de añadir el primer producto es necesario definir la vigencia de la promoción</p>
                </div>
                <div class="px-4 col-10">
                    <ValidationProvider v-slot="{ errors }" :rules="`required`" name="vigencia">
                        <el-date-picker v-model="vigencia" type="daterange" value-format="yyyy-MM-dd" format="dd-MM-yyyy" range-separator="-" start-placeholder="inicio" end-placeholder="Fin" :picker-options="pickerOptions" />
                        <p class="text-danger w-100 f-11">{{ errors[0] }}</p>
                    </ValidationProvider>
                </div>
                <div class="col-4 pt-4">
                    <el-checkbox v-model="form.estado" class="check-dark" :true-label="1" :false-label="0">
                        Activo
                    </el-checkbox>
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>
<script>
import {mapGetters} from 'vuex'
import Promociones from '~/services/promociones/promociones_cedis_admin'
import moment from 'moment'
export default {
    data(){
        return{
            id_cedi: parseInt(this.$route.params.id_cedis),
            loading:false,
            titulo: 'Editar vigencia estado',
            vigencia: [],
            pickerOptions: {
                disabledDate(time){
                    return time.getTime() < moment().subtract(1,'days');
                }
            },
            form:{
                id: null,
                estado: 0,
                inicio: null,
                fin: null,
                id_cedis: null,
                id_promocion: null
            },
            delBuscador:false
        }
    },
    methods: {
        toggle(promocion, buscador=false){
            this.limpiarFormulario()
            console.log(promocion);
            this.delBuscador = buscador
            this.form.id_promocion = promocion.id_promocion
            this.form.id_cedis = this.id_cedi
            if(promocion.id_cedi_promocion){
                this.form.id = promocion.id_cedi_promocion
                this.form.estado = promocion.estado
                let ini = moment(promocion.inicio).format('YYYY-MM-DD')
                let fin = moment(promocion.fin).format('YYYY-MM-DD')
                this.vigencia = [ini, fin]
            }else{
                this.titulo = 'Establecer vigencia'
                this.titulo = 'Antes de añadir el primer producto es necesario definir la vigencia de la promoción'
            }

            this.$refs.modalEditarVigencia.toggle()
        },
        async editarVigencia(){
            try {
                const valid = await this.$refs.validacion.validate()
                if(!valid){
                    this.notificacion('Mensaje', 'campos obligatorios', 'warning')
                    return false
                }
                this.form.inicio = this.vigencia[0]
                this.form.fin = this.vigencia[1]
                this.loading = true
                if(this.form.id){
                    var {data} =  await Promociones.editarCedisPromociones(this.form)
                    this.notificacion('Mensaje', 'Vigencia actualizada', 'success')
                }else{
                    this.notificacion('Mensaje', 'Vigencia creada', 'success')
                    var {data}  = await Promociones.crearCedisPromociones(this.form)
                }
                this.loading = false
                this.$emit('actualizar', data.cediPromocion, this.delBuscador)
                this.$refs.modalEditarVigencia.toggle()

            } catch (e){
                this.loading = false
                this.error_catch(e)
            }

        },
        limpiarFormulario(){
            this.form={
                id: null,
                estado: 0,
                inicio: null,
                fin: null,
                id_cedis: null,
                id_promocion: null
            }
            this.vigencia= []
        }
    }
}
</script>

<style lang="css" scoped>
</style>
